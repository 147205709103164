import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { FlashMessagesService } from '@dougs/ds';
import { SalesInvoice, SalesInvoiceDraft } from '@dougs/sales-invoice/dto';
import { SalesInvoicerStateService, SalesInvoiceStateService } from '@dougs/sales-invoice/shared';

@Injectable()
export class SalesInvoiceItemComponentService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly salesInvoicerStateService: SalesInvoicerStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly metricsService: MetricsService,
    @Inject(Window) private readonly window: Window,
  ) {}

  async duplicateInvoice(invoice: SalesInvoice | SalesInvoiceDraft): Promise<SalesInvoiceDraft | null> {
    const invoiceDuplicated: SalesInvoiceDraft | null = await this.salesInvoiceStateService.duplicateInvoice(invoice);

    if (invoiceDuplicated) {
      this.flashMessagesService.show('La facture a été dupliquée', {
        type: 'success',
        timeout: 5000,
      });
      await this.salesInvoicerStateService.refreshInvoicer(invoiceDuplicated.companyId ?? invoiceDuplicated.invoicerId);
      await this.router.navigateByUrl(
        this.routingService.createUrl([URL.SALES_INVOICE, invoiceDuplicated.id]).toString(),
      );
    }
    return invoiceDuplicated;
  }

  downloadPDF(invoice: SalesInvoice | SalesInvoiceDraft | null): void {
    if (invoice) {
      this.metricsService.pushMixpanelEvent('Invoicing PDF downloaded', {
        Type: invoice.isDraft ? 'Draft' : 'Invoice',
      });
      this.metricsService.pushIntercomEvent('Invoicing PDF downloaded', {
        Type: invoice.isDraft ? 'Draft' : 'Invoice',
      });
      const downloadUrl: string = invoice.isDraft
        ? `/companies/${invoice.companyId}/sales-invoices-drafts/${invoice.id}/actions/download`
        : `${(invoice as SalesInvoice).filePath}`;
      this.window.open(downloadUrl, '_blank');
    }
  }
}
